const asciiLogo = `
                    ,;+;,                     
                 ,;#######;,                  
              ,;#############;,               
          ,;####################;,            
       ,;##########################;,         
    ,;################################;,      
  \`'####################################'\`    
     \`'#######'\` ########## \`'#######'\`       
        \`'#'\`    ##########    \`'#'\`          
           ,+    ##########    +,             
        ,;###    ##########    ###;,          
    ,;#######    ##########    ########;,     
 ,#####   ###    ##########    ### \`+;#####,  
\`;#\`      ###    ##########    ###    \`;####;\`
          ###    ##########    ###;,          
          ###    ##########    ######+        
          ###    ##########    ###+           
           \`+    '########'    +\`             
                    \`##\`                      `

export default asciiLogo;